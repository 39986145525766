import React, { Component } from "react";
import { Modal } from "antd";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalStatus: false,
    };
  }
  componentDidMount() {
    if (!localStorage.getItem("radmin_id")) {
      localStorage.removeItem("radmin_id");
      localStorage.removeItem("radmin_name");
      window.location.href = "/";
    }
  }
  logout = () => {
    localStorage.removeItem("radmin_id");
    localStorage.removeItem("radmin_name");
    window.location.assign("/");
  };
  openModal = () => {
    this.setState({
      modalStatus: true,
    });
  };
  closeModal = () => {
    this.setState({
      modalStatus: false,
    });
  };
  render() {
    return (
      <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <a class="navbar-brand brand-logo" href="/dashboard">
            <img
              src="./assets/images/logo.png"
              alt="logo"
              style={{
                position: "relative",
                left: -6,
                maxWidth: 123,
                height: 27,
              }}
            />
          </a>
          <a class="navbar-brand brand-logo-mini" href="/dashboard">
            <img src="./assets/images/logo.png" alt="logo" />
          </a>
        </div>
        <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
          <button
            class="navbar-toggler navbar-toggler align-self-center"
            type="button"
            data-toggle="minimize"
          >
            {/* <span class="mdi mdi-menu"> */}
            <box-icon name="menu"></box-icon>
            {/* </span> */}
          </button>
          <ul class="navbar-nav navbar-nav-left"></ul>
          <ul class="navbar-nav navbar-nav-right">
            <li class="nav-item nav-search d-none d-lg-block"></li>
            <li class="nav-item dropdown">
              <a
                class="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center"
                id="notificationDropdown"
                href="#"
                data-toggle="dropdown"
              >
                <box-icon name="bell" color="#b59b9b" size="25px"></box-icon>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                aria-labelledby="notificationDropdown"
              >
                <p class="mb-0 font-weight-normal float-left dropdown-header">
                  Notifications
                </p>
                <a class="dropdown-item preview-item">
                  <div class="preview-thumbnail">
                    <div class="preview-icon bg-success">
                      <i class="mdi mdi-information mx-0"></i>
                    </div>
                  </div>
                  <div class="preview-item-content">
                    <h6 class="preview-subject font-weight-normal">
                      Application Error
                    </h6>
                    <p class="font-weight-light small-text mb-0 text-muted">
                      Just now
                    </p>
                  </div>
                </a>
              </div>
            </li>
            <li class="nav-item dropdown"></li>
            <li class="nav-item nav-language dropdown"></li>
            <li class="nav-item nav-profile dropdown">
              <a
                class="nav-link"
                href="#"
                data-toggle="dropdown"
                id="profileDropdown"
              >
                <box-icon
                  type="solid"
                  color="#6201ed"
                  size="30px"
                  name="user-circle"
                  style={{
                    position: "relative",
                    top: 5,
                  }}
                ></box-icon>
              </a>
              <div
                class="dropdown-menu dropdown-menu-right navbar-dropdown"
                aria-labelledby="profileDropdown"
              >
                <a class="dropdown-item" onClick={() => this.openModal()}>
                  <box-icon
                    type="solid"
                    name="log-in-circle"
                    color="#6201ed"
                    size="18px"
                    className="menu-icon"
                    style={{ paddingRight: 10 }}
                  ></box-icon>
                  Logout
                </a>
              </div>
            </li>
          </ul>
          <button
            class="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            data-toggle="offcanvas"
          >
            {/* <span class="mdi mdi-menu"></span> */}
            <box-icon name="menu"></box-icon>
          </button>
        </div>

        <Modal
          title="Logout"
          visible={this.state.modalStatus}
          onOk={() => this.logout()}
          onCancel={() => this.closeModal()}
        >
          <p style={{ fontSize: 18 }}>Are you sure you want to logout?</p>
        </Modal>
      </nav>
    );
  }
}
