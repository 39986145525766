import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import URLs from "./utils/apis";
import axios from "axios";
import { message, Spin, Button, Table, Modal } from "antd";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export default class ImportUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mghData: [],
      loading: false,
      excel_data_list: [],
      pagination: 20,
      records: [],
      single_user_data: {},
      partner_details: {},
      loading: false,
      is_loaded: false,
    };
  }

  handleChange = (event) => {
    const files = event.target.files;
    if (files && files[0]) this.handleFile(files[0]);
  };

  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.dataTrigger(data, file);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  // process_data = (index, list, newArray) => {
  //   console.log("index", index);
  //   if (list.length == index) {
  //     this.setState({
  //       records: newArray,
  //     });
  //     console.log("newArray", newArray);
  //     message.success("Processing is completed.");
  //     return;
  //   } else {
  //     if (index % 2 === 0) {
  //       // even no
  //       let num = Number(index + 1);
  //       this.process_data(num, list, newArray);
  //     } else {
  //       // odd no
  //       let currentObj = list[index];
  //       let p1 = new Promise((resolve, reject) => {
  //         let all_name_values = currentObj[0];
  //         let photo = currentObj[1];
  //         let value = JSON.stringify(all_name_values);
  //         let splited = value.split("\\r\\n");
  //         if (splited.length > 0) {
  //           let name_string = "";
  //           let husband_string = "";
  //           let house_no_string = "";
  //           let gender = "";
  //           let age = "";
  //           for (let item of splited) {
  //             if (
  //               item.includes("नाव :") &&
  //               !item.includes("पतीचे") &&
  //               !item.includes("वडीलांचे")
  //             ) {
  //               name_string = item ? item.split(":")[1] : "";
  //             }
  //             if (item.includes("पतीचे नाव") || item.includes("वडीलांचे नाव")) {
  //               husband_string = item ? item.split(":")[1] : "";
  //             }
  //             if (item.includes("घर क्रमांक")) {
  //               house_no_string = item ? item.split(":")[1] : "";
  //             }
  //             if (item.includes("लिंग")) {
  //               let splitted = item.split("लिंग");
  //               for (let item2 of splitted) {
  //                 if (item2.includes("वय")) {
  //                   age = item2.replace("वय : ", "");
  //                 } else {
  //                   gender = item2.replace(" : ", "").replace('"', "");
  //                 }
  //               }
  //             }
  //           }
  //           let obj = {
  //             name: name_string ? name_string.trim() : "",
  //             husband_name: husband_string ? husband_string.trim() : "",
  //             house_no: house_no_string ? house_no_string.trim() : "",
  //             gender: gender ? gender.trim() : "",
  //             age: age ? age.trim() : "",
  //             photo: photo ? photo.replace("\r\n", " ") : "",
  //           };
  //           console.log("obj", obj);
  //           newArray.push(obj);
  //           resolve();
  //           // console.log("name_string", name_string);
  //           // console.log("splited", splited);
  //         } else {
  //           resolve();
  //           // let num = Number(index + 1);
  //           // this.process_data(num, list, newArray);
  //         }
  //       });
  //       let p2 = new Promise((resolve, reject) => {
  //         let all_name_values = currentObj[2];
  //         let photo = currentObj[3];
  //         let value = JSON.stringify(all_name_values);
  //         let splited = value.split("\\r\\n");
  //         if (splited.length > 0) {
  //           let name_string = "";
  //           let husband_string = "";
  //           let house_no_string = "";
  //           let gender = "";
  //           let age = "";
  //           for (let item of splited) {
  //             if (
  //               item.includes("नाव :") &&
  //               !item.includes("पतीचे") &&
  //               !item.includes("वडीलांचे")
  //             ) {
  //               name_string = item ? item.split(":")[1] : "";
  //             }
  //             if (item.includes("पतीचे नाव") || item.includes("वडीलांचे नाव")) {
  //               husband_string = item ? item.split(":")[1] : "";
  //             }
  //             if (item.includes("घर क्रमांक")) {
  //               house_no_string = item ? item.split(":")[1] : "";
  //             }
  //             if (item.includes("लिंग")) {
  //               let splitted = item.split("लिंग");
  //               for (let item2 of splitted) {
  //                 if (item2.includes("वय")) {
  //                   age = item2.replace("वय : ", "");
  //                 } else {
  //                   gender = item2.replace(" : ", "").replace('"', "");
  //                 }
  //               }
  //             }
  //           }
  //           let obj = {
  //             name: name_string ? name_string.trim() : "",
  //             husband_name: husband_string ? husband_string.trim() : "",
  //             house_no: house_no_string ? house_no_string.trim() : "",
  //             gender: gender ? gender.trim() : "",
  //             age: age ? age.trim() : "",
  //             photo: photo ? photo.replace("\r\n", " ") : "",
  //           };
  //           console.log("obj", obj);
  //           newArray.push(obj);
  //           resolve();
  //         } else {
  //           resolve();
  //         }
  //       });
  //       let p3 = new Promise((resolve, reject) => {
  //         let all_name_values = currentObj[4];
  //         let photo = currentObj[5];
  //         let value = JSON.stringify(all_name_values);
  //         let splited = value.split("\\r\\n");
  //         if (splited.length > 0) {
  //           let name_string = "";
  //           let husband_string = "";
  //           let house_no_string = "";
  //           let gender = "";
  //           let age = "";
  //           for (let item of splited) {
  //             if (
  //               item.includes("नाव :") &&
  //               !item.includes("पतीचे") &&
  //               !item.includes("वडीलांचे")
  //             ) {
  //               name_string = item ? item.split(":")[1] : "";
  //             }
  //             if (item.includes("पतीचे नाव") || item.includes("वडीलांचे नाव")) {
  //               husband_string = item ? item.split(":")[1] : "";
  //             }
  //             if (item.includes("घर क्रमांक")) {
  //               house_no_string = item ? item.split(":")[1] : "";
  //             }
  //             if (item.includes("लिंग")) {
  //               let splitted = item.split("लिंग");
  //               for (let item2 of splitted) {
  //                 if (item2.includes("वय")) {
  //                   age = item2.replace("वय : ", "");
  //                 } else {
  //                   gender = item2.replace(" : ", "").replace('"', "");
  //                 }
  //               }
  //             }
  //           }
  //           let obj = {
  //             name: name_string ? name_string.trim() : "",
  //             husband_name: husband_string ? husband_string.trim() : "",
  //             house_no: house_no_string ? house_no_string.trim() : "",
  //             gender: gender ? gender.trim() : "",
  //             age: age ? age.trim() : "",
  //             photo: photo ? photo.replace("\r\n", " ") : "",
  //           };
  //           console.log("obj", obj);
  //           newArray.push(obj);
  //           resolve();
  //           // console.log("name_string", name_string);
  //           // console.log("splited", splited);
  //         } else {
  //           resolve();
  //           // let num = Number(index + 1);
  //           // this.process_data(num, list, newArray);
  //         }
  //       });
  //       Promise.all([p1, p2, p3]).then(() => {
  //         console.log("newArray", newArray);
  //         let num = Number(index + 1);
  //         this.process_data(num, list, newArray);
  //       });
  //     }
  //   }
  // };

  dataTrigger = (data, file) => {
    console.log("excel data", data);
    let shifted_data = data.shift();
    console.log("excel data length", shifted_data);
    // this.setState({
    //   excel_data_list: shifted_data,
    // },() => this.process_data(0, data, []));
    // return;

    let arr = [];
    let index = 0;
    for (let item of data) {
      let name = item[0];
      let email = item[1];
      let phone = item[2];
      let gender = item[3];
      let mother_toungue = item[4];
      let profile_created_by = item[5];
      let marital_status = item[6];
      let dob = item[7];
      let age = item[8];

      let born_reverted = item[9];
      let mathhab_followed = item[10];

      let about_self = item[11];

      let height = item[12];
      let disability = item[13];
      let state_id = item[14];
      let district_id = item[15];
      let taluka_id = item[16];
      let address = item[17];
      let highest_qua = item[18];
      let employee_in = item[19];
      let partner_age = item[20];
      let partner_marital_status = item[21];
      let partner_mathhab_followed = item[22];
      let partner_height = item[23];
      let partner_languages = item[24];
      let partner_education = item[25];
      let partner_disability = item[26];
      let partner_state_id = item[27];
      let partner_district_id = item[28];
      let obj = {
        marital_status: marital_status ? marital_status : "",
        name: name ? name : "",
        email: email ? email : "",
        phone: phone ? phone : "",
        gender: gender ? gender : "",
        mother_toungue: mother_toungue ? mother_toungue : "",
        profile_created_by: profile_created_by ? profile_created_by : "",
        dob: dob ? dob : "",
        age: age ? age : "",
        born_reverted: born_reverted ? born_reverted : "",
        mathhab_followed: mathhab_followed ? mathhab_followed : "",
        about_self: about_self ? about_self : "",
        height: height ? height : "",
        disability: disability ? disability : "",
        state_id: state_id ? state_id : "",
        district_id: district_id ? district_id : "",
        taluka_id: taluka_id ? taluka_id : "",
        address: address ? address : "",
        highest_qua: highest_qua ? highest_qua : "",
        employee_in: employee_in ? employee_in : "",
        partner_age: partner_age ? partner_age : "",
        partner_marital_status: partner_marital_status
          ? partner_marital_status
          : "",
        partner_mathhab_followed: partner_mathhab_followed
          ? partner_mathhab_followed
          : "",
        partner_height: partner_height ? partner_height : "",
        partner_languages: partner_languages ? partner_languages : "",
        partner_education: partner_education ? partner_education : "",
        partner_disability: partner_disability ? partner_disability : "",
        partner_state_id: partner_state_id ? partner_state_id : "",
        partner_district_id: partner_district_id ? partner_district_id : "",
      };
      // console.log("obj", obj);
      arr.push(obj);
      index++;
    }
    console.log("arr", arr);
    this.setState({
      records: arr,
      is_loaded: true,
    });
  };
  pleaseImportData = () => {
    this.setState({ loading: true });
    if (this.state.mghData.length == "0") {
      this.setState({ loading: false });
      message.warning("Please enter the proper data in sheet", 1);
      return;
    }

    let params = {
      data: JSON.stringify(this.state.mghData),
    };

    // Bridge.mghImportData
    //   .importDictionaryData(params)
    //   .then((result) => {
    //     if (result.status == "1") {
    //       this.setState({
    //         mghData: [],
    //         loading: false,
    //       });
    //       message.success(result.message, 5);
    //     } else {
    //       message.error(result.message, 5);
    //     }
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     // message.error("Something went wrong. Please try again!!");
    //   });
  };
  exportToCSV = () => {
    let arr = [];
    let index = 1;
    for (let item of this.state.records) {
      let obj = {
        name: item.name,
        email: item.email,
        phone: item.phone,
        gender: item.gender,
        mother_toungue: item.mother_toungue,
        profile_created_by: item.profile_created_by,
        marital_status: item.marital_status,
        dob: item.dob,
        age: item.age,
        born_reverted: item.born_reverted,
        mathhab_followed: item.mathhab_followed,
        about_self: item.about_self,
        height: item.height,
        disability: item.disability,
        state_id: item.state_id,
        district_id: item.district_id,
        taluka_id: item.taluka_id,
        address: item.address,
        highest_qua: item.highest_qua,
        employee_in: item.employee_in,
        partner_age: item.partner_age,
        partner_marital_status: item.partner_marital_status,
        partner_mathhab_followed: item.partner_mathhab_followed,
        partner_height: item.partner_height,
        partner_languages: item.partner_languages,
        partner_education: item.partner_education,
        partner_disability: item.partner_disability,
        partner_state_id: item.partner_state_id,
        partner_district_id: item.partner_district_id,
      };
      arr = [...arr, obj];
      index++;
    }
    let fileName = "Data";
    const ws = XLSX.utils.json_to_sheet(arr);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    message.success("Data exported successfully.");
  };
  handleTableChange = (pagination, filters, sorter) => {
    this.setState({
      pagination: pagination.pageSize,
    });
  };
  openModal = (item) => {
    this.setState({
      single_user_data: item,
      modalStatus: true,
    });
  };
  close_modal = () => {
    this.setState({
      single_user_data: {},
      modalStatus: false,
    });
  };
  import_users = () => {
    console.log("this.state.records", this.state.records);
    let params = {
      data: JSON.stringify(this.state.records),
    };
    this.setState({ loading: true });
    axios.post(URLs.import_users, params).then((response) => {
      console.log("response", response);
      if (response.data.status == "1") {
        message.success(response.data.message);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
        width: 114,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 114,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 114,
      },
      {
        title: "Phone No",
        dataIndex: "phone",
        key: "phone",
        width: 114,
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
        width: 114,
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 114,
        render: (item) => {
          return (
            <Button onClick={() => this.openModal(item)} type="primary">
              View Full Details
            </Button>
          );
        },
      },
    ];
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          sr_no: Number(index + 1),
          name: item.name,
          email: item.email,
          phone: item.phone,
          gender: item.gender,
          action: item,
        };
      });
    return (
      <div>
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            {/* <!-- partial:partials/_settings-panel.html --> */}
            <Sidebar />
            {/* <!-- partial --> */}
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                        <br />
                        <br />
                        <h4 className="mr-4 mb-0 font-weight-bold">
                          Import Users
                        </h4>
                        <div className="d-flex align-items-baseline dashboard-breadcrumb"></div>
                      </div>
                      <div className="d-flex">
                        <div className="btn-group mr-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="d-flex justify-content-between">
                      <div>
                        <SheetJSApp dataTrigger={this.dataTrigger} />

                        <br />
                        <strong style={{ color: "#000" }}>
                          (Note: Please select csv file)
                        </strong>
                      </div>
                      {this.state.is_loaded == true ? (
                        <div>
                          <Button
                            type="primary"
                            onClick={() => this.import_users()}
                          >
                            Import Users
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                            type="primary"
                            onClick={() => this.exportToCSV()}
                          >
                            Export
                          </Button>
                        </div>
                      ) : (
                        <a
                          href="./assets/document/users.xlsx"
                          target="_blank"
                          className="btn btn-primary"
                          style={{ height: "fit-content" }}
                        >
                          Download Execel Sheet
                        </a>
                      )}
                    </div>
                    <br />
                    <Spin spinning={this.state.loading}>
                      <div
                        style={{
                          maxWidth: "100%",
                          margin: "10px 30px",
                        }}
                      >
                        <Table
                          dataSource={dataSource}
                          columns={columns}
                          onChange={this.handleTableChange}
                        />
                      </div>
                    </Spin>
                  </div>
                </div>
              </div>
              {/* <!-- content-w/rapper ends --> */}
              {/* <!-- partial:partials/_footer.html --> */}
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2024 . All rights reserved.
                  </span>
                </div>
              </footer>
              {/* <!-- partial --> */}
            </div>
            {/* <!-- main-panel ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>

        <Modal
          title="User Details"
          visible={this.state.modalStatus}
          onOk={() => this.close_modal()}
          onCancel={() => this.close_modal()}
          okText="Ok"
          cancelText="Cancel"
          width={700}
        >
          <Spin spinning={this.state.loading}>
            <div className="profile-details-page">
              <br />
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">About Myself</h5>
                        </div>
                      </div>
                      <p>
                        {this.state.single_user_data.about_self
                          ? this.state.single_user_data.about_self
                          : "Not available."}
                      </p>
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">Basic Details</h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Name</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;{this.state.single_user_data.name}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Email</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.email
                                ? this.state.single_user_data.email
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Phone No</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.phone
                                ? this.state.single_user_data.phone
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Mother Tounge</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.mother_toungue}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Profile Created By</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.profile_created_by}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">Personal Details</h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Marital Status</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.marital_status
                                ? this.state.single_user_data.marital_status
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Date of Birth</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;{this.state.single_user_data.dob}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Math'hab Followed</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.mathhab_followed
                                ? this.state.single_user_data.mathhab_followed
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Born or reverted</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.born_reverted
                                ? this.state.single_user_data.born_reverted
                                : "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">Your Appearance </h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Height</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;{this.state.single_user_data.height}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Disability</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.disability
                                ? this.state.single_user_data.disability
                                : "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">Place you are from </h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">State Name</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.state_id
                                ? this.state.single_user_data.state_id
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">District Name</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.district_id
                                ? this.state.single_user_data.district_id
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Taluka Name</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.taluka_id
                                ? this.state.single_user_data.taluka_id
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Address</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.address
                                ? this.state.single_user_data.address
                                : "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">About Qualification</h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Highest Qualification</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.highest_qua
                                ? this.state.single_user_data.highest_qua
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Employee In</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.employee_in
                                ? this.state.single_user_data.employee_in
                                : "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">Partner Preferences </h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Age Range</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.partner_age
                                ? this.state.single_user_data.partner_age
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Martial Status</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data
                                .partner_marital_status
                                ? this.state.single_user_data
                                    .partner_marital_status
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Mazhab</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data
                                .partner_mathhab_followed
                                ? this.state.single_user_data
                                    .partner_mathhab_followed
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Height</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.partner_height
                                ? this.state.single_user_data.partner_height
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Language</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.partner_languages
                                ? this.state.single_user_data.partner_languages
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Education</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.partner_education
                                ? this.state.single_user_data.partner_education
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Disability</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.partner_disability
                                ? this.state.single_user_data.partner_disability
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">State</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.partner_state_id
                                ? this.state.single_user_data.partner_state_id
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Districts</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.partner_district_id
                                ? this.state.single_user_data
                                    .partner_district_id
                                : "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}

class SheetJSApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      cols: [],
    };
    this.handleFile = this.handleFile.bind(this);
  }
  handleFile = (file) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });

      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.props.dataTrigger(data, file);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  render() {
    return (
      <>
        <DataInput handleFile={this.handleFile} />
      </>
    );
  }
}

const SheetJSFT = ["xlsx"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

class DataInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(e) {
    const files = e.target.files;
    if (files && files[0]) this.props.handleFile(files[0]);
  }
  render() {
    return (
      <input
        style={{ paddingTop: 21 }}
        type="file"
        placeholder="default size"
        id="file"
        accept={SheetJSFT}
        onChange={this.handleChange}
      />
    );
  }
}
