// let BASE_URL = "https://sapi.rishtedari.in/api/";
// let image_url = "https://sapi.rishtedari.in/";

// let BASE_URL = "http://127.0.0.1:8000/api/";
// let image_url = "http://127.0.0.1:8000/";

let BASE_URL = "https://rishterdari-api.rishtedari.in/api/";
let image_url = "https://rishterdari-api.rishtedari.in/";

let URLs = {
  image_url: image_url,
  login: BASE_URL + "rishtedari-admin/login",
  get_dashboard_data: BASE_URL + "rishtedari-admin/get-dashboard-count",
  // admin
  get_user_list: BASE_URL + "rishtedari-admin/get_user_list",
  get_profile_details: BASE_URL + "rishtedari-admin/get_profile_details",
  get_district_list: BASE_URL + "rishtedari-admin/get_district_list",
  get_state_list: BASE_URL + "rishtedari-admin/get_state_list",

  import_users: BASE_URL + "rishtedari-admin/import_users",
};

export default URLs;
