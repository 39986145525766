import React, { Component } from "react";
// import "boxicons";

export default class Sidebar extends Component {
  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item">
            <a className="nav-link" href="/dashboard">
              <box-icon
                type="solid"
                name="check-shield"
                color="#6201ed"
                className="menu-icon"
                size="18px"
              ></box-icon>
              <span className="menu-title">Dashboard</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/registered-users">
              <box-icon
                type="solid"
                name="user-circle"
                color="#6201ed"
                size="18px"
                className="menu-icon"
              ></box-icon>
              <span className="menu-title">Registered Users</span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/import-users">
              <box-icon
                type="solid"
                name="user-circle"
                color="#6201ed"
                size="18px"
                className="menu-icon"
              ></box-icon>
              <span className="menu-title">Import Users</span>
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
