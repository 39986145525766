import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import URLs from "./utils/apis";
import axios from "axios";

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      total_users: 0,
      loading: false,
    };
  }
  componentDidMount() {
    this.get_data();
  }
  get_data = () => {
    this.setState({ loading: true });
    axios
      .get(URLs.get_dashboard_data)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            total_users: response.data.data,
            loading: false,
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  render() {
    return (
      <div>
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            {/* <!-- partial:partials/_settings-panel.html --> */}
            <Sidebar />
            {/* <!-- partial --> */}
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin">
                    <div className="d-flex justify-content-between flex-wrap">
                      <div className="d-flex align-items-center dashboard-header flex-wrap mb-3 mb-sm-0">
                        <h5 className="mr-4 mb-0 font-weight-bold">
                          Dashboard
                        </h5>
                        <div className="d-flex align-items-baseline dashboard-breadcrumb"></div>
                      </div>
                      <div className="d-flex">
                        <div className="btn-group mr-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-3 grid-margin stretch-card">
                    <div className="card icon-card-primary">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="icon mb-0 mb-md-2 mb-xl-0 mr-2">
                            <box-icon
                              name="user-circle"
                              color="#fff"
                              size="20px"
                              style={{
                                position: "relative",
                                top: 5,
                                left: 5,
                              }}
                            ></box-icon>
                          </div>
                          <p className="font-weight-medium mb-0">
                            Total Registered Users
                          </p>
                        </div>
                        <div className="d-flex align-items-center mt-3 flex-wrap">
                          <h3 className="font-weight-normal mb-0 mr-2">
                            {this.state.total_users}
                          </h3>
                          <div className="badge badge-outline-light badge-pill mt-md-2 mt-xl-0"></div>
                        </div>
                        <small className="font-weight-medium d-block mt-2">
                          All Time Total
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- content-w/rapper ends --> */}
              {/* <!-- partial:partials/_footer.html --> */}
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2024 . All rights reserved.
                  </span>
                </div>
              </footer>
              {/* <!-- partial --> */}
            </div>
            {/* <!-- main-panel ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    );
  }
}
