import React, { Component } from "react";
import { message, Spin } from "antd";
import URLs from "./utils/apis";
import axios from "axios";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
    };
  }
  login = () => {
    if (!this.state.email) {
      message.warning("Email address is required.");
      return;
    }
    if (!this.state.password) {
      message.warning("Password is required.");
      return;
    }
    let params = {
      email: this.state.email,
      password: this.state.password,
    };
    this.setState({ loading: true });
    axios
      .post(URLs.login, params)
      .then((response) => {
        if (response.data.status == "1") {
          message.success(response.data.message);
          localStorage.setItem("radmin_id", response.data.data[0].id);
          localStorage.setItem("radmin_name", response.data.data[0].username);
          this.setState({ loading: false });
          window.location.assign("/dashboard");
          return;
        } else {
          this.setState({ loading: false });
          message.warning(response.data.message);
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
        message.warning("Please try again.");
      });
  };
  render() {
    return (
      <div className="login-page">
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-stretch auth auth-img-bg">
              <div className="row flex-grow">
                <div className="col-lg-6 d-flex align-items-center justify-content-center m-auto">
                  <div className="auth-form-transparent text-left p-3">
                    <div className="brand-logo">
                      <img
                        src="./assets/images/logo.png"
                        alt="logo"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          margin: "auto",
                          maxWidth: 185,
                        }}
                      />
                    </div>
                    <h4 className="text-center">Log In</h4>
                    <h6 className="text-center" style={{ fontWeight: "400" }}>
                      Happy to see you again!
                    </h6>
                    <Spin spinning={this.state.loading}>
                      <form className="pt-3">
                        <div className="form-group">
                          <label for="exampleInputEmail">Username</label>
                          <div className="input-group">
                            <div className="input-group-prepend bg-transparent">
                              <span className="input-group-text bg-transparent border-right-0">
                                <box-icon
                                  type="solid"
                                  name="user"
                                  size="17px"
                                  color="#6201ed"
                                  style={{
                                    position: "relative",
                                    left: 6,
                                  }}
                                ></box-icon>
                              </span>
                            </div>
                            <input
                              type="text"
                              className="form-control form-control-lg border-left-0"
                              id="exampleInputEmail"
                              placeholder="Email Address"
                              value={this.state.email}
                              onChange={(e) =>
                                this.setState({ email: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <label for="exampleInputPassword">Password</label>
                          <div className="input-group">
                            <div className="input-group-prepend bg-transparent">
                              <span className="input-group-text bg-transparent border-right-0">
                                <box-icon
                                  size="17px"
                                  name="lock-alt"
                                  color="#6201ed"
                                  style={{
                                    position: "relative",
                                    left: 6,
                                  }}
                                ></box-icon>
                              </span>
                            </div>
                            <input
                              type="password"
                              className="form-control form-control-lg border-left-0"
                              id="exampleInputPassword"
                              placeholder="Password"
                              value={this.state.password}
                              onChange={(e) =>
                                this.setState({ password: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className="my-3">
                          <button
                            className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                            type="button"
                            onClick={() => this.login()}
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                    </Spin>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- content-wrapper ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>
      </div>
    );
  }
}
