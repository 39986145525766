import React, { Component } from "react";
import Header from "./includes/Header";
import Sidebar from "./includes/Sidebar";
import { Table, Modal, Spin, Menu, Dropdown, Button, message } from "antd";
import URLs from "./utils/apis";
import axios from "axios";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      crecords: [],
      modalStatus: false,
      loader: false,
      name: "",
      email: "",
      mobile: "",
      formLoader: false,

      edit_id: "",
      edit_name: "",
      edit_email: "",
      edit_mobile: "",
      edit_modal_status: false,
      d_list: [],
      loading: false,
      single_user_data: {},
      partner_details: {},
      profile_image_url: "",
      state_list: [],
      district_list: [],

      state_id: "",
      district_id: "",
      image_url: "",
      imageModalStatus: false,
      current_item: 0,

      show_next_button: true,
      show_previous_button: false,
    };
  }
  componentDidMount() {
    this.get_list();
    this.get_state_list();
  }
  get_state_list = () => {
    axios
      .get(URLs.get_state_list)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            loading: false,
            state_list: response.data.data,
          });
          return;
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  get_district_list = () => {
    let params = {
      state_id: this.state.state_id,
    };
    axios
      .post(URLs.get_district_list, params)
      .then((response) => {
        if (response.data.status == "1") {
          this.setState({
            loading: false,
            district_id: "",
            district_list: response.data.data,
          });
          return;
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  get_list = () => {
    axios
      .get(URLs.get_user_list)
      .then((response) => {
        if (response.data.status == "1") {
          let arr = [];
          let index = 1;
          for (let item of response.data.data) {
            item.index = index;
            arr.push(item);
            index++;
          }
          this.setState({
            loading: false,
            records: arr,
            crecords: arr,
          });
          return;
        } else {
          this.setState({ loading: false });
          return;
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.error("Warning:", error);
      });
  };
  close_modal = () => {
    this.setState({
      modalStatus: false,
    });
  };
  open_modal = (item) => {
    let image = URLs.image_url + item.profile;
    this.setState({
      modalStatus: true,
      single_user_data: item,
      partner_details: item.partner_details,
      profile_image_url: item.profile
        ? image
        : "./assets/images/no-image-available.jpg",
      current_item: item.index,
    });
  };
  filter_data = () => {
    let filtered_data = [];
    let searchInput = this.state.searchText;
    let list = this.state.crecords;
    if (searchInput) {
      for (let item of list) {
        if (
          (item.name &&
            item.name.toLowerCase().includes(searchInput.toLowerCase())) ||
          (item.email &&
            item.email.toLowerCase().includes(searchInput.toLowerCase())) ||
          (item.phone &&
            item.phone.toLowerCase().includes(searchInput.toLowerCase())) ||
          (item.gender &&
            item.gender.toLowerCase().includes(searchInput.toLowerCase()))
        ) {
          filtered_data.push(item);
        }
      }
    } else {
      filtered_data = list;
    }

    let state_arr = [];
    if (this.state.state_id) {
      let arr = [];
      for (let item of filtered_data) {
        if (item.state_id == this.state.state_id) {
          arr.push(item);
        }
      }
      state_arr = arr;
    } else {
      state_arr = filtered_data;
    }

    let district_arr = [];
    if (this.state.district_id) {
      let arr = [];
      for (let item of state_arr) {
        if (item.district_id == this.state.district_id) {
          arr.push(item);
        }
      }
      district_arr = arr;
    } else {
      district_arr = state_arr;
    }

    this.setState({
      records: district_arr,
    });
  };
  show_image = () => {
    this.setState({ imageModalStatus: true });
  };
  show_next = () => {
    let records = this.state.records;
    let currentIndex = this.state.current_item;
    let nextIndex = Number(currentIndex) + 1;
    let single = "";
    for (let item of records) {
      if (item.index == nextIndex) {
        single = item;
      }
    }
    if (!single || single == "") {
      message.warning("Next record is not available.");
      return;
    }
    let image = URLs.image_url + single.profile;
    this.setState({
      modalStatus: true,
      single_user_data: single,
      partner_details: single.partner_details,
      profile_image_url: single.profile
        ? image
        : "./assets/images/no-image-available.jpg",
      current_item: single.index,
    });

    // set buttons
    let nextIndex2 = Number(nextIndex);
    let show_previous = false;
    let show_next = false;
    for (let item of records) {
      if (item.index < nextIndex2) {
        show_previous = true;
      }
      if (item.index > nextIndex2) {
        show_next = true;
      }
    }
    this.setState({
      show_next_button: show_next,
      show_previous_button: show_previous,
    });
  };
  show_previous = () => {
    let records = this.state.records;
    let currentIndex = this.state.current_item;
    let nextIndex = Number(currentIndex) - 1;
    let single = "";
    for (let item of records) {
      if (item.index == nextIndex) {
        single = item;
      }
    }
    if (!single || single == "") {
      message.warning("Previous record is not available.");
      return;
    }
    let image = URLs.image_url + single.profile;
    this.setState({
      modalStatus: true,
      single_user_data: single,
      partner_details: single.partner_details,
      profile_image_url: single.profile
        ? image
        : "./assets/images/no-image-available.jpg",
      current_item: single.index,
    });

    // set buttons
    let nextIndex2 = Number(nextIndex);
    let show_previous = false;
    let show_next = false;
    for (let item of records) {
      if (item.index < nextIndex2) {
        show_previous = true;
      }
      if (item.index > nextIndex2) {
        show_next = true;
      }
    }
    this.setState({
      show_next_button: show_next,
      show_previous_button: show_previous,
    });
  };
  render() {
    const dataSource =
      this.state.records &&
      this.state.records.map((item, index) => {
        return {
          key: index,
          sr_no: Number(index + 1),
          name: item.name,
          email: item.email ? item.email : "---",
          mobile: item.phone,
          gender: item.gender,
          action: item,
        };
      });
    const columns = [
      {
        title: "Sr No",
        dataIndex: "sr_no",
        key: "sr_no",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Mobile No",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "Gender",
        dataIndex: "gender",
        key: "gender",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (item) => {
          const menu = (
            <Menu className="action-menu participant-action-menu">
              <Menu.Item key={`show_edit_modal_${item.index}`}>
                <a
                  className="action-buttons"
                  href="#!"
                  onClick={() => this.open_modal(item)}
                >
                  <i
                    className="fas fa-file-alt"
                    style={{ color: "#000", fontSize: 18 }}
                  ></i>
                  <span style={{ fontSize: 14, marginLeft: 10 }}>
                    View Full Details
                  </span>
                </a>
              </Menu.Item>
            </Menu>
          );

          return (
            <div style={{ width: "100%" }}>
              <Dropdown overlay={menu}>
                <a
                  className="ant-dropdown-link action-link"
                  onClick={(e) => e.preventDefault()}
                  style={{
                    background: "#ddd",
                    paddingTop: 13,
                    borderRadius: 4,
                  }}
                >
                  <box-icon name="dots-vertical" color="#000"></box-icon>
                </a>
              </Dropdown>
            </div>
          );
        },
      },
    ];
    return (
      <div>
        <div className="container-scroller">
          <Header />
          <div className="container-fluid page-body-wrapper">
            {/* <!-- partial:partials/_settings-panel.html --> */}
            <Sidebar />
            {/* <!-- partial --> */}
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title" style={{ fontSize: 24 }}>
                      Registered Users
                    </h4>
                    <br />
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <div className="row">
                            <div className="col-lg-4">
                              <div class="form-group">
                                <label for="exampleInputUsername1">
                                  Search
                                </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  id="exampleInputUsername1"
                                  placeholder="Search"
                                  style={{ width: "100%" }}
                                  value={this.state.searchText}
                                  onChange={(e) =>
                                    this.setState(
                                      {
                                        searchText: e.target.value,
                                      },
                                      () => this.filter_data()
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div class="form-group">
                                <label for="exampleInputUsername1">State</label>
                                <select
                                  class="form-control"
                                  value={this.state.state_id}
                                  onChange={(e) => {
                                    this.setState(
                                      {
                                        state_id: e.target.value,
                                      },
                                      () => {
                                        this.get_district_list();
                                        this.filter_data();
                                      }
                                    );
                                  }}
                                >
                                  <option value={""}>Select</option>
                                  {this.state.state_list &&
                                    this.state.state_list.map((item, index) => {
                                      return (
                                        <option value={item.state_id}>
                                          {item.state_name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div class="form-group">
                                <label for="exampleInputUsername1">
                                  District
                                </label>
                                <select
                                  class="form-control"
                                  value={this.state.district_id}
                                  onChange={(e) =>
                                    this.setState(
                                      {
                                        district_id: e.target.value,
                                      },
                                      () => this.filter_data()
                                    )
                                  }
                                >
                                  <option value={""}>Select</option>
                                  {this.state.district_list &&
                                    this.state.district_list.map(
                                      (item, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={item.district_id}
                                          >
                                            {item.name}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </div>
                          </div>
                          <Table
                            dataSource={dataSource}
                            columns={columns}
                            loading={this.state.loading}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- content-w/rapper ends --> */}
              {/* <!-- partial:partials/_footer.html --> */}
              <footer className="footer">
                <div className="d-sm-flex justify-content-center justify-content-sm-between">
                  <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                    Copyright © 2024 . All rights reserved.
                  </span>
                </div>
              </footer>
              {/* <!-- partial --> */}
            </div>
            {/* <!-- main-panel ends --> */}
          </div>
          {/* <!-- page-body-wrapper ends --> */}
        </div>

        <Modal
          title="Profile Image"
          visible={this.state.imageModalStatus}
          onOk={() => this.setState({ imageModalStatus: false })}
          onCancel={() => this.setState({ imageModalStatus: false })}
          okText="Ok"
          cancelText="Cancel"
          footer={[
            <Button
              type="primary"
              onClick={() => this.setState({ imageModalStatus: false })}
            >
              Ok
            </Button>,
          ]}
          width={500}
        >
          <div>
            <img
              src={this.state.profile_image_url}
              style={{
                width: 300,
                height: 300,
                borderRadius: "50%",
                objectFit: "cover",
                padding: 6,
                border: "1px solid #787878",
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              }}
              onClick={() => this.show_image()}
            />
          </div>
        </Modal>

        <Modal
          title={"User Details - " + this.state.single_user_data.name}
          visible={this.state.modalStatus}
          onOk={() => this.close_modal()}
          onCancel={() => this.close_modal()}
          okText="Ok"
          cancelText="Cancel"
          width={750}
        >
          <Spin spinning={this.state.formLoader}>
            <div className="profile-details-page">
              {this.state.show_previous_button == true ? (
                <div
                  className={"left-arrow"}
                  onClick={() => this.show_previous()}
                >
                  <FiChevronLeft size={"27px"} strokeWidth={"2.8px"} />
                </div>
              ) : (
                <div className={"left-arrow disabled"}>
                  <FiChevronLeft size={"27px"} strokeWidth={"2.8px"} />
                </div>
              )}
              <div
                className={
                  this.state.show_next_button == true
                    ? "right-arrow"
                    : "right-arrow disabled"
                }
                onClick={
                  this.state.show_next_button == true
                    ? () => this.show_next()
                    : false
                }
              >
                <FiChevronRight size={"27px"} strokeWidth={"2.8px"} />
              </div>
              <br />
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="profile-image">
                      {this.state.profile_image_url && (
                        <img
                          src={this.state.profile_image_url}
                          style={{
                            width: 100,
                            height: 100,
                            borderRadius: "50%",
                            objectFit: "cover",
                            padding: 6,
                            border: "1px solid #787878",
                            display: "flex",
                            justifyContent: "center",
                            margin: "0 auto",
                            cursor: "pointer",
                          }}
                          onClick={() => this.show_image()}
                        />
                      )}
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">About Myself</h5>
                        </div>
                      </div>
                      <p>
                        {this.state.single_user_data.about_self
                          ? this.state.single_user_data.about_self
                          : "Not available."}
                      </p>
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">Basic Details</h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Name</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;{this.state.single_user_data.name}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Email</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.email
                                ? this.state.single_user_data.email
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Phone No</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.phone
                                ? this.state.single_user_data.phone
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Mother Tounge</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.mother_toungue}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Profile Created By</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.profile_created_by}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">Personal Details</h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Marital Status</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.marital_status
                                ? this.state.single_user_data.marital_status
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Date of Birth</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;{this.state.single_user_data.dob}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Math'hab Followed</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.mathhab_followed
                                ? this.state.single_user_data.mathhab_followed
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Born or reverted</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.born_or_reverted
                                ? this.state.single_user_data.born_or_reverted
                                : "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">Your Appearance </h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Height</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;{this.state.single_user_data.height}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Disability</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.disability
                                ? this.state.single_user_data.disability
                                : "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">Place you are from </h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">State Name</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.state_name
                                ? this.state.single_user_data.state_name
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">District Name</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.district_name
                                ? this.state.single_user_data.district_name
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Taluka Name</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.taluka_name
                                ? this.state.single_user_data.taluka_name
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Address</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.address
                                ? this.state.single_user_data.address
                                : "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">About Qualification</h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Highest Qualification</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.highest_qualification
                                ? this.state.single_user_data
                                    .highest_qualification
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Employee In</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.single_user_data.employee_in
                                ? this.state.single_user_data.employee_in
                                : "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="about-self">
                      <div className="details-header d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          <box-icon
                            type="solid"
                            name="user"
                            size={"19px"}
                          ></box-icon>
                          &nbsp;&nbsp;
                          <h5 className="mb-0 pb-0">Partner Preferences </h5>
                        </div>
                      </div>
                      <div className="all-list">
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Age Range</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.partner_details.age
                                ? this.state.partner_details.age
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Martial Status</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.partner_details.pmarital_status
                                ? this.state.partner_details.pmarital_status
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Mazhab</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.partner_details.pmathhab_followed
                                ? this.state.partner_details.pmathhab_followed
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Height</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.partner_details.pheight
                                ? this.state.partner_details.pheight
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Language</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.partner_details.planguages
                                ? this.state.partner_details.planguages
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Education</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.partner_details.peducation
                                ? this.state.partner_details.peducation
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Disability</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.partner_details.pdisability
                                ? this.state.partner_details.pdisability
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">State</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.partner_details.state_name
                                ? this.state.partner_details.state_name
                                : "---"}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-6">
                            <p className="param">Districts</p>
                          </div>
                          <div className="col-6">
                            <p className="value">
                              : &nbsp;&nbsp;
                              {this.state.partner_details.p_districts
                                ? this.state.partner_details.p_districts
                                : "---"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}
